import styled from 'styled-components';
import media from 'styled-media-query';

const Title = styled.h1`
  font-size: 27px;
  color: #186492;
  margin-top: 5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  letter-spacing: 1.1px;

  ${media.lessThan('small')`
    margin-top: 16px;
  `}
`;

const SubTitle = styled.h2`
  font-size: 22px;
  color: #186492;
  opacity: 0.75;
  margin-bottom: 1rem;
  font-weight: bold;
  letter-spacing: 0.86px;
`;

const Description = styled.h3`
  font-size: 16px;
  color: #26546d;
  opacity: 0.75;
  margin-bottom: 1rem;
`;

const Image = styled.img`
  width: 100%;
  max-height: 600px;
  object-fit: cover;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const Text = styled.p`
  color: #26546d;
  font-size: 16px;
`;

export { Title, SubTitle, Description, Image, Text };
