import React from 'react';
import * as S from './styled';

const Content = ({ title, subtitle, description, images = [], text, children }) => (
  <>
    {title && <S.Title>{title}</S.Title>}
    {subtitle && <S.SubTitle>{subtitle}</S.SubTitle>}
    {description && <S.Description>{description}</S.Description>}
    {text && <S.Text dangerouslySetInnerHTML={{ __html: text }} />}
    {images.length && images.map((image) => <S.Image src={image} />)}
    {children}
  </>
);

export default Content;
